import React from 'react';
import { Layout, Row, Col } from 'antd';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import ContactForm from '../../components/PageFragments/ContactForm';
import SEO from '../../components/Seo';

const Contact = () => (
  <Layout className="outerPadding">
    <Layout className="container">
      <SEO
        title="Contact"
        description="Dexterous Senior Data Scientist with M. Phil in Statistics and 7+ years of hands-on experience leveraging Machine learning Models,
Algorithms, Statistical Modeling, and Data Analysis to uncover insights of data problems and drives in business growth significantly.
Involved in Data Science open source community and passionate about Deep Reinforcement Learning."
        path="/contact"
        keywords={['Mruga', 'Mruga Adhvaryu', 'Adhvaryu', 'Vyas', 'Berlin', 'Germany', 'Data Analyst', 'Data scientist', 'Senior Data scientist', 'Machine learning', 'Deep learning', 'Management Information System']}
      />
      <Header />
      <SidebarWrapper>
        <div className="marginTopTitle">
          <h1 className="titleSeparate">Contact</h1>
        </div>
        <Row gutter={[40, 20]}>
          <Col sm={24} md={24} lg={12}>
            <img
              src="../contact.png"
              alt="contact"
              className="widthFull contactImgBorder"
            />
          </Col>
          <ContactForm />
        </Row>
      </SidebarWrapper>
    </Layout>
  </Layout>
);

export default Contact;
